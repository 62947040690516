import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BackgroundLayout } from "../components/BackgroundLayout/BackgroundLayout"
import { SEO } from "../components/SEO/Seo"
import { useHomeQuery } from "../hooks/home/useHomeQuery"

import "../sass/main.scss"

// markup
const ErrorPage500 = () => {
  const {
    wpPage: {
      homePageCustomFields: { homePageLogo: data }
    }
  } = useHomeQuery()
  const imageData = getImage(data.localFile)

  return (
    <main>
      <SEO title="Error 500" />
      <BackgroundLayout>
        <div className="content-wrapper home-logo">
          <GatsbyImage image={imageData} alt="Moon Lark" className="error__logo" />
          <div className="error__content">
            <>
              <h1>500</h1>
              <h1>Internal Server Error</h1>
            </>
            <Link className="error__content--button" to={"/"}>
              <p>Back home</p>
            </Link>
          </div>
        </div>
      </BackgroundLayout>
    </main>
  )
}

export default ErrorPage500
